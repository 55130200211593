<dx-card>
  @if (img) {
    <dx-card-image fit="proportional"
      url="{{img}}" (click)="gotoNews(owner)">
    </dx-card-image>
  }
  <dx-card-content>
    <div class="newsitem-metas">
      <dx-text type="it" color="gray-50">{{publicationDate}}</dx-text>
      @if (owner) {
        <dx-tag class="newsitem-tag" label="{{owner}}" type="static" theme="gray"></dx-tag>
      }
    </div>
    <dx-text type="ps" color="gray-83" id="headline" class="newsitem-header spacing-8" (click)="gotoNews(owner)">{{title|stripHtml}}</dx-text>
    <div class="read-more">
      <a href="{{link}}" target="{{owner}}"><dx-button label="{{textObjects[88]}}" type="text" icon="pfeil-rechts" iconposition="right" gaEvent="click" gaCategory="{{gaCategoryValue}}" gaLabel="{{owner}}:{{title}}"></dx-button></a>
    </div>
  </dx-card-content>
</dx-card>
