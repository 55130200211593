import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core'
import {BreakpointAware} from "@dvag/design-system"
import {TextService} from "../../services/text.service"
import {DesignSystemModule} from '@dvag/design-system-angular'


@Component({
  standalone: true,
  selector: 'app-scaling',
  templateUrl: './scaling.component.html',
  imports: [
    DesignSystemModule
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ScalingComponent implements OnInit, AfterViewInit {
  private breakpointAware: BreakpointAware = new BreakpointAware(() => this.checkBreakPoint())
  announcementBarBreakpointVisible = false
  announcementBarVisible = false
  maxRatio = 180
  text = ''
  linktext = ''
  url = ''

  constructor(private textService: TextService) {
    // empty.
  }

  checkBreakPoint() {
    this.announcementBarBreakpointVisible = this.breakpointAware.getEffectiveValue({
      mq1: false,
      mq4: true
    })
  }

  ngOnInit() {
    this.url = this.textService.get(179).toString()
    this.text = this.textService.get(178).toString()
    this.linktext = this.textService.get(180).toString()
  }

  ngAfterViewInit() {
    this.checkBreakPoint()

    const updatePixelRatio = () => {
      const pr = window.devicePixelRatio
      const prString = (pr * 100).toFixed(0)
      console.log(prString)
      matchMedia(`(resolution: ${pr}dppx)`).addEventListener('change', updatePixelRatio, {once: true})
      this.announcementBarVisible = (+prString > this.maxRatio && this.announcementBarBreakpointVisible) || false
    }
    updatePixelRatio()
  }
}
