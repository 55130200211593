/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HistoryResponse } from './historyResponse';


export interface FeatureResponse { 
    components?: Array<FeatureResponse.ComponentsEnum>;
    description?: string;
    history?: Array<HistoryResponse>;
    id?: number;
    isActive?: boolean;
    isFeatureDisabled?: boolean;
    link?: string;
    name?: string;
}
export namespace FeatureResponse {
    export type ComponentsEnum = 'Backend' | 'Frontend';
    export const ComponentsEnum = {
        Backend: 'Backend' as ComponentsEnum,
        Frontend: 'Frontend' as ComponentsEnum
    };
}


