import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core'
import { Tag } from '../../../generated/openapi/search/search'
import { Router } from '@angular/router'
import { Translation } from '../../../generated/openapi/textservice'
import { TextService } from '../../services/text.service'
import { DesignSystemModule } from '@dvag/design-system-angular'

import { StripHtmlPipe } from '../../pipes/strip-html.pipe'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'

@Component({
  standalone: true,
  selector: 'app-newsitem',
  templateUrl: './newsitem.component.html',
  styleUrls: ['./newsitem.component.scss'],
  imports: [
    DesignSystemModule,
    StripHtmlPipe,
    NgxGoogleAnalyticsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class NewsitemComponent implements OnInit {
  @Input() img = ''
  @Input() publicationDate: string | undefined = ''
  @Input() owner = ''
  @Input() tags: Array<Tag> = []
  @Input() link: URL | string = new URL('https://www.dvag.de')
  @Input() title: string = ''
  @Input() text: string = ''
  @Input() targetName: string = ''
  @Input() gaCategoryValue: string = ''

  placeholderImg = 'assets/img/news_placeholder400x160.png'
  textObjects: Array<Translation> = []

  constructor(
    private router: Router,
    private textService: TextService) {
    // empty
  }

  ngOnInit() {
    this.img = this.img || this.placeholderImg
    this.textObjects = this.textService.getTextsObjectsForIds(88)
  }

  gotoNews(owner: string) {
    this.router.navigate([]).then(() => {
      window.open(this.link.toString(), owner)
    })
  }
}
