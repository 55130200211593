<ng-template #courseOfTheYear let-kennzahlen="kennzahlen">
  <div class="unitOverview__segments unitOverview__courseOfTheYear">
    <dx-text type="ps" color="gray-83" class="unitOverview__segmentHeadline">
      {{textsObjects[168]}}
    </dx-text>
    <div class="unitOverview__segmentContent">
      <canvas class="barChart" baseChart
        [data]="kennzahlen"
        [options]="barChartOptions"
        [type]="'bar'">
      </canvas>
    </div><!--end .unitOverview__segmentContent-->
  </div><!--end .unitOverview__courseOfTheYear-->
</ng-template>
<ng-template #individualGoal let-label="label" let-sublabel="sublabel" let-type="type">
  <div class="unitOverview__segments unitOverview__individualGoal">
    <dx-text type="ps" color="gray-83" class="unitOverview__segmentHeadline">
      {{textsObjects[169]}}
    </dx-text>
    <div class="unitOverview__segmentContent">
      <div class="individualGoal__donutChartContainer">
        <div class="individualGoal__donutChartLabelContainer">
          <dx-text type="ps" color="gray-83" class="individualGoal__donutChartLabel"
          [class.hidden]="!textinputHidden">{{label | number:'1.1-1':'de-DE'}}</dx-text>
          <dx-number-input class="individualGoal__donutChartInput" [class.hidden]="textinputHidden" value="{{sublabel}}"
          #individualGoalInput></dx-number-input>
          <dx-text type="its" color="gray-66" class="individualGoal__donutChartSublabel"
          [class.hidden]="!textinputHidden">von {{sublabel | number:'1.1-1':'de-DE'}}</dx-text>
        </div>
        <dx-donut-chart colorscheme="custom" class="individualGoal__donutChart">
          <dx-chart-sample color="blue-60" value="{{(label / (sublabel || label) * 100) || 0}}"></dx-chart-sample>
          <dx-chart-sample color="gray-33" value="{{(100 - (label / sublabel* 100)) || 100}}"></dx-chart-sample>
        </dx-donut-chart>
        @if (textinputHidden) {
          <dx-button type="text" icon="edit" class="individualGoal__donutChartButton" (click)="textinputHidden = false"
            gaEvent="click"
            gaCategory="{{textsObjects[161]}}:{{textsObjects[172]}}>{{textsObjects[169]}}>bearbeiten>uid-0095"
          gaLabel="{{textsObjects[172]}}>{{textsObjects[169]}}>bearbeiten"></dx-button>
        }
        @else {
          <dx-button type="primary-s" icon="check" class="individualGoal__donutChartButton"
            (click)="textinputHidden = true; setTarget(type, individualGoalInput.value)"
            gaEvent="click"
            gaCategory="{{textsObjects[161]}}:{{textsObjects[172]}}>{{textsObjects[169]}}>speichern>uid-0096"
          gaLabel="{{textsObjects[172]}}>{{textsObjects[169]}}>speichern"></dx-button>
        }
      </div>
      <!--end .individualGoal__donutChartContainer-->
    </div>
    <!--end .unitOverview__segmentContent-->
  </div>
</ng-template>
<ng-template #submittedUnits let-units="units">
  <div class="unitOverview__segments unitOverview__submittedUnits">
    <dx-text type="ps" color="gray-83" class="unitOverview__segmentHeadline">
      {{textsObjects[170]}}
    </dx-text>
    <div class="unitOverview__segmentContent">
      <div class="submittedUnits__Content">
        <dx-text type="h1" color="gray-83" class="submittedUnits__ContentHeadline">{{units | number:'1.1-1':'de-DE'}}</dx-text>
        <dx-text type="ps" color="gray-66" class="submittedUnits__ContentSubline">{{textsObjects[171]}} {{getCurrentDate()}}</dx-text>
      </div>
      <!--end .submittedUnits__Content-->
    </div>
    <!--end .unitOverview__segmentContent-->
  </div>
</ng-template>

<dx-grid mq1="12/*">
  <dx-card class="unitOverview">
    <dx-card-content>
      <div class="unitOverview__container">
        <div class="unitOverview__header" [class.hidden]="unitOverviewHidden">
          <dx-text type="ps">{{textsObjects[172]}}</dx-text>
          <div class="unitOverview__visibility" [class.hidden]="unitOverviewHidden">
            <dx-button type="text" icon="{{unitOverviewHidden ? 'auge' : 'auge-durchgestrichen'}}"
              (click)="unitOverviewHidden = !unitOverviewHidden"
              gaEvent="click"
              gaCategory="{{textsObjects[161]}}:{{textsObjects[172]}}>{{unitOverviewHidden ? textsObjects[174]: textsObjects[173]}}>{{unitOverviewHidden ? 'uid-0098' : 'uid-0097'}}"
            gaLabel="{{textsObjects[172]}}>{{unitOverviewHidden ? textsObjects[174]: textsObjects[173]}}"></dx-button>
          </div>
        </div>
        <div class="unitOverview__tabBarContainer" [class.hidden]="unitOverviewHidden">
          <dx-tab-bar class="unitOverview__tabBar" (tabChange)="trackTabChange($event.detail)">
            <dx-tab-page label="Eigen" value="1">
              <dx-grid mq1="12/12/12" mq3="6-6/12" mq4="3-4-5" mq5="3-3-6" rowgap="0" class="unitOverview__Container">
                <ng-container
                *ngTemplateOutlet="submittedUnits; context: {units: kennzahlen.eingereichtesGeschaeft?.eigen?.aktuellerMonat?.einheiten}"></ng-container>

                <ng-container
                  *ngTemplateOutlet="individualGoal; context: {
                  label: kennzahlen.eingereichtesGeschaeft?.eigen?.aktuellerMonat?.einheiten,
                  sublabel: kennzahlen.kennzahlenZiele?.eigen,
                  type: 'eigen'
                }"></ng-container>

                <ng-container
                *ngTemplateOutlet="courseOfTheYear; context: {kennzahlen: charts.eigen}"></ng-container>
              </dx-grid>
            </dx-tab-page>
            <dx-tab-page label="Gruppe" value="2">
              <dx-grid mq1="12/12/12" mq3="6-6/12" mq4="3-4-5" mq5="3-3-6" rowgap="0" class="unitOverview__Container">
                <ng-container
                *ngTemplateOutlet="submittedUnits; context: {units: kennzahlen.eingereichtesGeschaeft?.gruppe?.aktuellerMonat?.einheiten}"></ng-container>

                <ng-container
                  *ngTemplateOutlet="individualGoal; context: {
                  label: kennzahlen.eingereichtesGeschaeft?.gruppe?.aktuellerMonat?.einheiten,
                  sublabel: kennzahlen.kennzahlenZiele?.gruppe,
                  type: 'gruppe'
                }"></ng-container>

                <ng-container
                *ngTemplateOutlet="courseOfTheYear; context: {kennzahlen: charts.gruppe}"></ng-container>
              </dx-grid><!--end .unitOverview__Container-->

            </dx-tab-page>
            <dx-tab-page label="Gesamt" value="3">
              <dx-grid mq1="12/12/12" mq3="6-6/12" mq4="3-4-5" mq5="3-3-6" rowgap="0" class="unitOverview__Container">
                <ng-container
                *ngTemplateOutlet="submittedUnits; context: {units: kennzahlen.eingereichtesGeschaeft?.gesamt?.aktuellerMonat?.einheiten}"></ng-container>

                <ng-container
                  *ngTemplateOutlet="individualGoal; context: {
                  label: kennzahlen.eingereichtesGeschaeft?.gesamt?.aktuellerMonat?.einheiten,
                  sublabel: kennzahlen.kennzahlenZiele?.gesamt,
                  type: 'gesamt'
                }"></ng-container>

                <ng-container
                *ngTemplateOutlet="courseOfTheYear; context: {kennzahlen: charts.gesamt}"></ng-container>
              </dx-grid><!--end .unitOverview__Container-->
            </dx-tab-page>
          </dx-tab-bar>
        </div>
      </div>
    </dx-card-content>
  </dx-card>
</dx-grid>
