/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureRequest { 
    id?: number;
    isActive?: boolean;
    name?: string;
    link?: string;
    description?: string;
    dvagId?: string;
    isFeatureDisabled?: boolean;
    components?: Array<FeatureRequest.ComponentsEnum>;
}
export namespace FeatureRequest {
    export type ComponentsEnum = 'Backend' | 'Frontend';
    export const ComponentsEnum = {
        Backend: 'Backend' as ComponentsEnum,
        Frontend: 'Frontend' as ComponentsEnum
    };
}


