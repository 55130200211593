import { Injectable } from '@angular/core'
import {FeatureControllerService, FeatureResponse} from "../../generated/openapi/featureToggle"
import { HttpHeaders } from "@angular/common/http"
import {EnvironmentService} from "./environment.service"
import {firstValueFrom} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FeaturetoggleService {
  private _features: FeatureResponse[] = []

  constructor(private featureToggle: FeatureControllerService, private envService: EnvironmentService) {
    //this.setFeatures()
  }

  setFeatures() {
    this.envService.getConfig().then((config) => {
      if (['Lokal', 'Entwicklung', 'Abnahme'].indexOf(config.environment) !== -1) {
        // misused for DX-Header
        this._features.push({
          name: 'CO2 Neutral',
          isActive: true
        })
        // misused for deprecated Sidebar
        this._features.push({
          name: 'TestFeature',
          isActive: false
        })
        this._features.push({
          name: 'appSearchAzureFunction',
          isActive: true
        })
      } else if (['Produktion'].indexOf(config.environment) !== -1) {
        // misused for DX-Header
        this._features.push({
          name: 'CO2 Neutral',
          isActive: true
        })
        // misused for deprecated Sidebar
        this._features.push({
          name: 'TestFeature',
          isActive: false
        })
      } else {
        this.getFeatures().then()
      }
    })
  }

  async getAppToken():Promise<string> {
    const config = await this.envService.getConfig()
    let url =  config.apis.appToken
    const response = await fetch(url,  {
      credentials: 'include'
    })
    const answer = await response.json()
    return answer.access_token
  }

  async getFeatures() : Promise<FeatureResponse[]> {
    const bearerToken = await this.getAppToken()
    this.featureToggle.defaultHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + bearerToken
    })

    const features = await firstValueFrom(this.featureToggle.getFeaturesUsingGET('benutzerkonto'))

    return new Promise<FeatureResponse[]>((resolve) => {
      if (this._features.length) {
        resolve(this._features)
      } else {
        this._features = features
          resolve(this._features)
      }
    })
  }

  isFeatureActive(feature:string): boolean {
    return this._features.find((itm: FeatureResponse) => itm.name === feature)?.isActive || false
  }

  async init(): Promise<FeatureResponse[]> {
    if (this._features.length) {
      return(this._features)
    }
    return this._features
  }
}
