import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core'
import {AuthService} from "../../services/auth.service"
import { HttpClient } from "@angular/common/http"
import {getUserFullName} from "../../utils"
import {Router} from "@angular/router"
import {TextService} from "../../services/text.service"
import {BrowsersizeService} from "../../services/browsersize.service"
import {Translation} from "../../../generated/openapi/textservice"
import {DesignSystemModule} from '@dvag/design-system-angular'
import {SearchinputComponent} from '../../uicomponents/searchinput/searchinput.component'

@Component({
  standalone: true,
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    DesignSystemModule,
    SearchinputComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DashboardHeaderComponent implements OnInit{

  username = ''
  term = ''
  textObjects: Array<Translation> = []

  constructor(private auth: AuthService, private http: HttpClient, private router:Router, private textService: TextService, public browsersizeService: BrowsersizeService) {
    this.loadUser().then()
  }

  ngOnInit() {
    this.textObjects = this.textService.getTextsObjectsForIds(11, 21, 68, 69)
  }

  async loadUser() {
    let user = await this.auth.getUser()
    this.username = getUserFullName(user)
  }

  search() {
    this.router.navigate(['/search', this.term]).then()
  }
}
