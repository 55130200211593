import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core'
import {
  ActivationEnd,
  Router
} from "@angular/router"
import {TextService} from "../services/text.service"
import {Translation} from "../../generated/openapi/textservice"
import {ROUTES} from "../app.routes"
import {EnvironmentService} from "../services/environment.service"
import {DesignSystemModule} from '@dvag/design-system-angular'
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics'
import { InsightsService } from '../services/insights.service'

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    DesignSystemModule,
    NgxGoogleAnalyticsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class FooterComponent implements OnInit {
  backgroundColor = ''
  whiteBackgroundComponents = [
    'HtmlerrorpagesComponent'
  ]
  textsObjects: Array<Translation> = []
  feedbackformUrl: URL | string = ''

  constructor(public router: Router, private textService: TextService, private envService: EnvironmentService, private insightsService: InsightsService) {
    router.resetConfig(ROUTES)
    this.loadEnv().then()
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.feedbackformUrl = config.apis.feedbackForm
  }

  ngOnInit() {
    this.textsObjects = this.textService.getTextsObjectsForIds(112,118,160,155,133,161)
    // seperate footer background color for search page
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof ActivationEnd) {
          const componentName = event?.snapshot?.routeConfig?.component?.name || ''
          this.backgroundColor = 'background'
          if (this.whiteBackgroundComponents.includes(componentName)) {
            this.backgroundColor = 'white'
          }
        }
      }
    )
  }

  gotoFeedbackForm() {
    this.router.navigate([]).then(() => {
      window.open(this.feedbackformUrl, '_blank')
    })
    this.insightsService.logEvent('Click FooterFeedbackButton')
  }
}
