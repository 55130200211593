import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, OnDestroy, Renderer2 } from '@angular/core'
import { AuthService } from './services/auth.service'
import { ActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { Components } from '@dvag/design-system'
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Data as InfoCB } from '../generated/openapi/infocb'
import { searchMandanten } from './types'
import { Anwendungssuche } from '../generated/openapi/search/anwendungen'
import { AnwendungssucheService } from './services/anwendungssuche.service'
import { Favorit } from '../generated/openapi/favoriten'
import {
  LaunchAppDetail
} from '@dvag/design-system/dist/types/components/scaffold/dx-header/dx-header-app-favorites/app-favorite'
import { TextService } from './services/text.service'
import { Translation } from '../generated/openapi/textservice'
import { EnvironmentService } from './services/environment.service'
import { BrowsersizeService } from './services/browsersize.service'
import { WatchdogService } from './services/watchdog.service'
import DxModal = Components.DxModal
import DxSingleSelect = Components.DxSingleSelect
import { DesignSystemModule } from '@dvag/design-system-angular'
import { DOCUMENT } from '@angular/common'
import { KeycloakService } from 'keycloak-angular'
import { FooterComponent } from './footer/footer.component'
import { UserlaneComponent } from './userlane/userlane.component'
import { ScalingComponent } from './uicomponents/scaling/scaling.component'
import { firstValueFrom, interval, startWith, Subscription, switchMap } from 'rxjs'
import { InsightsService } from './services/insights.service'

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    DesignSystemModule,
    NgxGoogleAnalyticsModule,
    RouterOutlet,
    FooterComponent,
    UserlaneComponent,
    ScalingComponent
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  user: InfoCB = {
    ssoid: '',
    userid: ''
  }
  isEditor = false
  editMode: boolean = JSON.parse(window.sessionStorage.getItem('text-editmode') || 'false')
  avatar?: URL | string
  benutzerkonto: URL | string = ''
  logoffUrlKeycloak: URL | string = ''
  logoffUrlMeineDVAG: URL | string = ''
  avatarUrl: URL | string = ''
  systemstatusUrl: URL | string = ''
  faq: URL | string = ''
  itStatusMeldung: URL | string = ''
  vbweltUrl: URL | string = ''
  beratungsweltUrl: URL | string = ''
  itweltUrl: URL | string = ''
  feedbackformUrl: URL | string = ''
  gtmId = ''
  gtm4Id = ''
  adobeTrackingUrl: URL | string = ''
  favApps: Array<Anwendungssuche> = []
  fullname = ''
  window = window
  settingsOpen = false
  favoritesOpen = false
  userOpen = false
  burgerOpen = false
  textsObjects: Array<Translation> = []
  headerTheme = ''
  goldHeaderThemeComponents = [
    'DashboardComponent',
    '_DashboardComponent'
  ]

  private pollingObservable: Subscription | undefined

  constructor(private auth: AuthService,
    public router: Router,
    public gaService: GoogleAnalyticsService,
    public browsersizeService: BrowsersizeService,
    private http: HttpClient,
    private anwendungssucheService: AnwendungssucheService,
    private textService: TextService,
    private envService: EnvironmentService,
    private watchdogService: WatchdogService,
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private keycloakService: KeycloakService,
    private insightsService: InsightsService) {
    this.loadEnv().then(() => {
      this.loadUser().then(() => {
        this.loadApps()
        this.getAvatar().then()
      })

      this.loadAdobeAnalytics()
      this.router.events.subscribe((item:any) => {
        if (item instanceof NavigationEnd) {
          this.gaService.gtag('config', this.gtmId, { 'page_path': item.url })
          this.gaService.gtag('config', this.gtm4Id, { 'page_path': item.url })
        }
      })
    })
    this.textsObjects = this.textService.getTextsObjectsForIds(42, 43, 44, 51, 52, 12, 53, 54, 118, 161, 157, 139, 141, 152, 143, 144, 142, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 156, 155, 157)

    this.anwendungssucheService.favChanged$.subscribe(() => {
      this.favApps = this.anwendungssucheService.favoriteApps
    })
  }

  ngOnInit() {
    this.startWatchdog()

    this._elementRef.nativeElement.removeAttribute('ng-version')

    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof ActivationEnd) {
          this.setHeaderTheme(
            event?.snapshot?.routeConfig?.component?.name || ''
          )
        }
      })
  }

  ngOnDestroy(): void {
    this.pollingObservable?.unsubscribe()
  }

  startWatchdog(){
    this.pollingObservable = interval(300000)
      .pipe(
        startWith(0),
        switchMap(() => this.watchdogService.getVersion())
      )
      .subscribe({
          next: version => {
            if (this.watchdogService.buildTime === 0) {
              this.watchdogService.buildTime = version.buildTime
            } else {
              if (this.watchdogService.buildTime !== version.buildTime) {
                this.reload()
              }
            }
          },
          error: err => {
            this.insightsService.logException(err)
          }
        }
      )
  }

  reload() {
    this.document.location.reload()
  }

  setHeaderTheme(componentName: string) {
    this.headerTheme = 'white'
    if (this.goldHeaderThemeComponents.includes(componentName)) {
      this.headerTheme = 'gold'
    }
  }

  async loadEnv() {
    await this.keycloakService.isLoggedIn()

    const config = await this.envService.getConfig()
    this.benutzerkonto = config.apis.benutzerkonto
    this.logoffUrlKeycloak = config.apis.keycloak + config.apis.keycloakLogout
    this.logoffUrlMeineDVAG = config.apis.logoff
    this.avatarUrl = config.apis.avatar
    this.systemstatusUrl = config.apis.systemstatus
    this.faq = config.apis.faq
    this.itStatusMeldung = config.apis.itStatusMeldung
    this.vbweltUrl = config.apis.vbwelt
    this.beratungsweltUrl = config.apis.beratungswelt
    this.itweltUrl = config.apis.itwelt
    this.feedbackformUrl = config.apis.feedbackForm
    this.gtmId = config.apis.gtmId
    this.gtm4Id = config.apis.gtm4Id
    this.adobeTrackingUrl = config.apis.adobeTracking
  }

  async loadUser() {
    this.user = await this.auth.getUser()
    this.isEditor = !!this.user.iseditor
    this.fullname = this.getFirstAndLastname()
  }

  loadAdobeAnalytics() {
    const script = this.renderer.createElement('script')
    script.type = 'text/javascript'
    script.src = this.adobeTrackingUrl
    script.async = 'async'
    this.renderer.appendChild(this.document.body, script)
  }

  loadApps() {
    const promises = [
      firstValueFrom(this.anwendungssucheService.fetchApps(searchMandanten['meineDVAG-Keycloak']))
    ]
    if (this.user.isinnendienst) {
      promises.push(firstValueFrom(this.anwendungssucheService.fetchApps(searchMandanten['meineDVAG-ID-Keycloak'])))
    }

    this.anwendungssucheService.favChanged$.subscribe(() => {
      Promise.all(promises).then(() => {
        this.favApps = this.anwendungssucheService.favoriteApps
      })
    })
  }

  confirmText(modal: DxModal, editmodeToggle: DxSingleSelect) {
    this.editMode = (editmodeToggle.value === 'yes')
    window.sessionStorage.setItem('text-editmode', this.editMode.toString())
    modal.visible = false

    this.reloadRoute()
  }

  editModeToYesNo(editmode: boolean): 'yes' | 'no' {
    return ((editmode && 'yes') || (!editmode && 'no') || 'no')
  }

  private reloadRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigate([this.router.url]).then()
  }

  getFirstAndLastname(): string {
    if (
      (this.user.isassistent || this.user.isvertreter) &&
      (this.user.vertretenvon?.vorname || this.user.vertretenvon?.name)
    ) {
      return this.user.vertretenvon?.vorname + ' ' + this.user.vertretenvon?.name
    } else if (this.user) {
      return this.user.vorname + ' ' + this.user.name
    } else {
      return 'Max Mustermann'
    }
  }

  async getAvatar() {
    if (this.user.userid.match(/^\d{7,}$/)) {
      this.http.get<Array<{
        id: string
        aufloesung: string
        kategorie: string
        url: URL
        publicUrl: URL
      }>>(`${this.avatarUrl}/${this.user.userid}/bilder/vbportraithomepage`, {
        responseType: 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + await this.keycloakService.getToken()
        })
      }).subscribe(data => {
        this.avatar = data
          .filter(item => item.url && item.aufloesung === '144x144')
          .pop()?.url?.toString() || ''
      })
    }
  }

  saveFavorites(favs: Array<string>) {
    let apiFavs: Array<Favorit> = []
    for (let i = 0; i < favs.length; i++) {
      apiFavs.push({ pos: i, anwendungsId: favs[i] })
    }

    this.gaService.event(this.textsObjects[154].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[154] + '>' + 'uid-0069')
    this.insightsService.logEvent(this.textsObjects[154].singular as unknown as string)
    this.anwendungssucheService.writeFavorites(apiFavs)
  }

  removeFav(appid: string) {
    this.gaService.event(this.textsObjects[153].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[153] + '>' + 'uid-0068')
    this.insightsService.logEvent(this.textsObjects[153].singular as unknown as string)
    this.anwendungssucheService.removeFavorite(appid)
  }

  gotoBenutzerkonto() {
    this.gaService.event(this.textsObjects[144].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[144] + '>' + 'uid-0057')
    this.insightsService.logEvent(this.textsObjects[144].singular as unknown as string)
    this.router.navigate([]).then(() => {
      window.open(this.benutzerkonto.toString(), '_blank')
    })
  }

  gotoFeedbackForm() {
    this.router.navigate([]).then(() => {
      window.open(this.feedbackformUrl, '_blank')
    })
    this.insightsService.logEvent('Click NavFeedbackButton')
  }

  gotoUrl(url: string) {
    location.href = url
  }

  gotoLogoff() {
    this.gaService.event(this.textsObjects[145].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[145] + '>' + 'uid-0058')
    this.gotoUrl(this.logoffUrlKeycloak.toString())
  }

  gotoApps() {
    this.gaService.event(this.textsObjects[147].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[147] + '>' + 'uid-0060')
    this.insightsService.logEvent(this.textsObjects[147].singular as unknown as string)
    this.router.navigate(['/apps']).then()
  }

  launchApp(e: LaunchAppDetail) {
    const appid = e.appid
    const url = this.favApps.filter((app) => app.id === appid)[0]?.applicationURL
    const appname = this.favApps.filter((app) => app.id === appid)[0]?.title
    this.gaService.event(this.textsObjects[146].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[146] + '>' + 'uid-0059', appname)
    this.router.navigate([]).then(() => {
      window.open(url, '_blank')
    })
  }

  gotoHome() {
    this.router.navigate(['/']).then()
    this.insightsService.logEvent(this.textsObjects[155].singular as unknown as string)
    this.gaService.event(this.textsObjects[155].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[155] + '>' + this.textsObjects[139] + '>' + 'uid-0077')
  }

  gotoHomeLogo() {
    this.insightsService.logEvent(this.textsObjects[139].singular as unknown as string)
    this.router.navigate(['/']).then()
    this.gaService.event(this.textsObjects[139].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[139] + '>' + 'uid-0053')
  }

  gotoVBWelt() {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[42] + '>' + 'uid-0088')
    this.insightsService.logEvent(this.textsObjects[42].singular as unknown as string)
    this.router.navigate([]).then(() => {
      window.open(this.vbweltUrl.toString(), '_blank')
    })
  }

  gotoITWelt() {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[43] + '>' + 'uid-0089')
    this.insightsService.logEvent(this.textsObjects[43].singular as unknown as string)
    this.router.navigate([]).then(() => {
      window.open(this.itweltUrl.toString(), '_blank')
    })
  }

  gotoBeratungswelt() {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[44] + '>' + 'uid-0090')
    this.insightsService.logEvent(this.textsObjects[44].singular as unknown as string)
    this.router.navigate([]).then(() => {
      window.open(this.beratungsweltUrl.toString(), '_blank')
    })
  }
  changeUser() {
    const loginParms = {
      action: 'CONTEXT_CHANGE',
      scope: 'context_profile'
    }
    this.keycloakService.login(loginParms).then()
  }

  writeGASettings() {
    this.settingsOpen = !this.settingsOpen
  }

  writeGAFavorites() {
    if (this.favoritesOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[141] + '>' + this.textsObjects[152] + '>' + 'uid-0065')
      this.favoritesOpen = false
    } else {
      this.gaService.event(this.textsObjects[141].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[141] + '>' + 'uid-0054')
      this.favoritesOpen = true
    }
  }

  writeGAUser() {
    if (this.userOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[142] + '>' + this.textsObjects[152] + '>' + 'uid-0066')
      this.userOpen = false
    } else {
      this.gaService.event(this.textsObjects[142].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[142] + '>' + 'uid-0055')
      this.userOpen = true
    }
  }

  writeGABurger() {
    if (this.burgerOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[143] + '>' + this.textsObjects[152] + '>' + 'uid-0067')
      this.burgerOpen = false
    } else {
      this.gaService.event(this.textsObjects[143].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[143] + '>' + 'uid-0056')
      this.burgerOpen = true
    }
  }

  allApps() {
    this.gaService.event(this.textsObjects[156].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[156] + '>' + 'uid-0078')
    this.insightsService.logEvent(this.textsObjects[156].singular as unknown as string)
    this.router.navigate(['/apps']).then()
  }

  editApps() {
    this.gaService.event(this.textsObjects[148].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[148] + '>' + 'uid-0061')
    this.insightsService.logEvent(this.textsObjects[148].singular as unknown as string)
  }
}
