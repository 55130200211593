<dx-grid mq1="12/*" mq5="12/6-6">
  <dx-text type="h4" class="container-headline mt-0">{{texts[66]}}</dx-text>
  <dx-card class="spacing-service-contact">
    <dx-card-content>
      <div style="">
        <div class="spacing-16 mt-0">
          <dx-text type="h5">{{texts[70]}}</dx-text>
        </div>
        <div class="spacing-16 mt-0 mb-0">
          <dx-text>{{texts[71]}}</dx-text>
          <dx-text color="gray-83"> {{texts[73]}}</dx-text>
          <br/>
          <dx-text>{{texts[72]}}</dx-text>
          <dx-text color="gray-83"> {{texts[74]}}</dx-text>
        </div>

        <div class="spacing-32 mb-16">
          <dx-text type="h5">{{texts[75]}}</dx-text>
        </div>
        <dx-text>{{texts[71]}}</dx-text>
        <dx-text color="gray-83"> {{texts[76]}}</dx-text>
        <br/>
        <dx-text>{{texts[77]}}</dx-text>
        <dx-text color="gray-83"> {{texts[78]}}</dx-text>
        <br/>
        <dx-text>{{texts[79]}} </dx-text>
        <dx-link url="mailto:{{texts[81]}}" gaEvent="click"
          gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[75]}}>{{texts[79]}}>uid-0015"
          gaLabel="{{texts[66]}}>{{texts[75]}}>{{texts[79]}}>uid-0015">
          <dx-text> {{texts[80]}}</dx-text>
        </dx-link>
        <div class="spacing-32 mb-16 hyphenation">
          <dx-text type="h5">{{texts[87]}}</dx-text>
        </div>
        <dx-text [innerHTML]="texts[82] | safeHtml"></dx-text>
        <div class="spacing-8">
          <a
            [href]="dldbsSearchUrl"
            target="_blank" rel="noopener" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[87]}}>{{texts[15]}}>uid-0016"
            gaLabel="{{texts[66]}}>{{texts[87]}}>{{texts[15]}}">
            <dx-button id="btn-direktionsbeauftragte" [label]="texts[15]" type="text" icon="pfeil-rechts"
            iconposition="right"></dx-button>
          </a>
        </div>
        <div class="spacing-32 mb-16">
          <dx-text type="h5">{{texts[119]}}</dx-text>
        </div>
        <dx-text [innerHTML]="texts[120] | safeHtml"></dx-text>
        <div class="spacing-8">
          <a href="{{serviceContactsUrl}}" target="_blank" rel="noopener" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[165]}}>{{texts[166]}}>{{texts[166]}}>uid-0091"
            gaLabel="{{texts[165]}}>{{texts[166]}}>{{texts[166]}}">
            <dx-button [label]="texts[117]" type="secondary-m" icon="pfeil-rechts" iconposition="right"></dx-button>
          </a>
        </div>
      </div>
    </dx-card-content>
  </dx-card>
  <dx-card>
    <dx-card-content>
      <div style="">
        <div class="spacing-16 mt-0">
          <dx-text type="h5">{{texts[85]}}</dx-text>
        </div>
        <dx-accordion-group>
          <dx-accordion id="qs-quick-info1" [label]="texts[5]" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[85]}}>{{texts[130]}}>uid-0019"
            gaLabel="{{texts[66]}}>{{texts[85]}}>{{texts[130]}}">
            <dx-text [innerHTML]="texts[2] | safeHtml"></dx-text>
          </dx-accordion>
          <dx-accordion id="qs-quick-info2" [label]="texts[6]" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[85]}}>{{texts[131]}}>uid-0020"
            gaLabel="{{texts[66]}}>{{texts[85]}}>{{texts[131]}}">
            <dx-text [innerHTML]="texts[3] | safeHtml"></dx-text>
          </dx-accordion>
          <dx-accordion id="qs-quick-info3" [label]="texts[7]" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[85]}}>{{texts[132]}}>uid-0021"
            gaLabel="{{texts[66]}}>{{texts[85]}}>{{texts[132]}}">
            <dx-text [innerHTML]="texts[9] | safeHtml"></dx-text>
            <a style="text-decoration: none" [href]="textObjects[8].properties?.url"
              [target]="textObjects[8].properties?.linkTarget" gaEvent="click"
              gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[85]}}>{{texts[132]}}>{{texts[8]}}>uid-0022"
              gaLabel="{{texts[66]}}>{{texts[85]}}>{{texts[132]}}>{{texts[8]}}">
              <dx-text color="default" [innerHTML]="textObjects[8] | safeHtml"></dx-text>
            </a>
          </dx-accordion>
        </dx-accordion-group>
        <a [href]="textObjects[10].properties?.url" [target]="textObjects[10].properties?.linkTarget"
          gaEvent="click" gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[10]}}>uid-0023"
          gaLabel="{{texts[66]}}>{{texts[10]}}">
          <dx-button id="btn-it-faqs" class="mt-16" label="{{textObjects[10]}}" type="secondary-m"
          icon="pfeil-rechts" iconposition="right"></dx-button>
        </a>

        <div class="spacing-32 mb-16">
          <dx-text type="h5">{{texts[83]}}</dx-text>
        </div>
        <dx-text>{{texts[84]}}</dx-text>
        <div class="spacing-8">
          <a href="https://system.status.dvag" target="_blank" rel="noopener" gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[83]}}>{{texts[12]}}>uid-0017"
            gaLabel="{{texts[66]}}>{{texts[83]}}>{{texts[12]}}">
            <dx-button id="btn-systemstatus" [label]="texts[12]" type="text" icon="pfeil-rechts"
            iconposition="right"></dx-button>
          </a>
        </div>
        <div class="spacing-8">
          <a href="https://www.itwelt.dvag/aktuelles/it-statusmeldungen" target="_blank" rel="noopener"
            gaEvent="click"
            gaCategory="{{texts[161]}}:{{texts[155]}}>{{texts[66]}}>{{texts[83]}}>{{texts[13]}}>uid-0018"
            gaLabel="{{texts[66]}}>{{texts[83]}}>{{texts[13]}}">
            <dx-button id="btn-it-statusmeldung" [label]="texts[13]" type="text" icon="pfeil-rechts"
            iconposition="right"></dx-button>
          </a>
        </div>
        @if (isInnendienst) {
          <div class="spacing-8">
            <a href="https://dvagatlas.sharepoint.com/sites/P00074" target="_blank" rel="noopener" gaEvent="click"
              gaCategory="Redesign:Service Kontakte" gaLabel="Statusmeldungen Innendienst anzeigen">
              <dx-button id="btn-statusmeldungen" [label]="texts[14]" type="text" icon="pfeil-rechts"
              iconposition="right"></dx-button>
            </a>
          </div>
        }
      </div>
    </dx-card-content>
  </dx-card>
</dx-grid>
