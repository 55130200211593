<dx-container type="page" color="gold">
  <dx-grid mq1="12/*" mq4="(2)-8-(2)/*">
    <div class="greeting">
      <dx-text type="h3" color="white"><div>{{textObjects[68]}} {{username}}</div></dx-text>
    </div>
    <div class="search-field">
      <app-searchinput
        id="search-applications"
        [(term)]="term"
        (termChange)="search()"
        class="search-input"
        placeholder="{{browsersizeService.isLowerThan.mq4 ? textObjects[21] : textObjects[11]}}"
        [showIconOnly]="browsersizeService.isLowerThan.mq2" [searchPage]="false"
      ></app-searchinput>
    </div>
  </dx-grid>
</dx-container>
<dx-fd-separator class="seperator" fromcolor="gold" tocolor="background"></dx-fd-separator>
