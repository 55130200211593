import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Version } from '../types'

@Injectable({
  providedIn: 'root'
})
export class WatchdogService {

  private _buildTime = 0

  constructor(private http: HttpClient) {
    //
  }

  getVersion(tstamp: number = Date.now()): Observable<Version> {
    return this.http.get<Version>(`./assets/version/version.json?${tstamp}`)
  }

  get buildTime(): number {
    return this._buildTime
  }

  set buildTime(value: number) {
    this._buildTime = value
  }
}
