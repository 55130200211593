@if (announcementBarVisible) {
  <dx-announcement-bar
    color="background"
    icon="information"
    iconcolor="gold"
    slot="announcement-bar"
    >
    {{text}} <dx-link target="_blank" url="{{url}}">{{linktext}}</dx-link>.
  </dx-announcement-bar>
}
