import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core'
import {
  ControllerService as VBKennzahlenService,
  Geschaeft,
  KennzahlenResponse,
  Metadata, Sparte,
  Stammdaten
} from '../../../generated/openapi/vbkennzahlen'
import { EnvironmentService } from '../../services/environment.service'
import { DecimalPipe, formatDate, NgTemplateOutlet } from '@angular/common'
import { Translation } from '../../../generated/openapi/textservice'
import { TextService } from '../../services/text.service'
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { ColorPalette } from '@dvag/design-system'
import { ChartConfiguration } from 'chart.js'
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts'
import { KeycloakService } from 'keycloak-angular'
import {HttpHeaders} from "@angular/common/http"

@Component({
  standalone: true,
  selector: 'app-unit-overview',
  templateUrl: './unit-overview.component.html',
  styleUrls: ['./unit-overview.component.scss'],
  imports: [
    DesignSystemModule,
    DecimalPipe,
    NgTemplateOutlet,
    NgxGoogleAnalyticsModule,
    NgChartsModule
],
  providers: [
    {provide: NgChartsConfiguration, useValue: {generateColors: false}}
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class UnitOverviewComponent implements OnInit {

  static chartLabelSteps = 10000
  barChartOptions: ChartConfiguration<'bar'>['options'] = {
    backgroundColor: ColorPalette.getColorCode('blue-60'),
    layout: {
      padding: 0
    },
    animations: {
      y: {
        duration: 1000,
        easing: 'linear',
        loop: false
      }
    },
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        border: {
          dash: [2, 4]
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  }

  vbKennzahlenGeschaeft: Geschaeft
    = {
    eigen: {
      aktuellerMonat: {
        einheiten: 0,
        zeitraum: 0
      },
      aktuellesJahr: {
        einheiten: 0,
        zeitraum: 0
      },
      historie:
        [{
          einheiten: 0,
          zeitraum: 0
        }]
    },
    gesamt: {
      aktuellerMonat: {
        einheiten: 0,
        zeitraum: 0
      },
      aktuellesJahr: {
        einheiten: 0,
        zeitraum: 0
      },
      historie:
        [{
          einheiten: 0,
          zeitraum: 0
        }]
    },
    gruppe: {
      aktuellerMonat: {
        einheiten: 0,
        zeitraum: 0
      },
      aktuellesJahr: {
        einheiten: 0,
        zeitraum: 0
      },
      historie:
        [{
          einheiten: 0,
          zeitraum: 0
        }]
    }
  }

  textinputHidden = true
  unitOverviewHidden = false
  kennzahlen: KennzahlenResponse = {
    abgerechnetesGeschaeft: this.vbKennzahlenGeschaeft,
    eingereichtesGeschaeft: this.vbKennzahlenGeschaeft,
    stammdaten: {} as Stammdaten,
    metadata: {} as Metadata,
    kennzahlenZiele: {
      eigen: 0,
      gruppe: 0,
      gesamt: 0
    }
  }

  charts: {
    [key in keyof Geschaeft]: ChartConfiguration<'bar'>['data']
  } = {
    eigen: {
      labels: [],
      datasets: []
    },
    gruppe: {
      labels: [],
      datasets: []
    },
    gesamt: {
      labels: [],
      datasets: []
    }
  }
  vbKennzahlenServiceUrl: URL | string = ''
  textsObjects: Array<Translation> = []

  constructor(
    private vbKennzahlenService: VBKennzahlenService,
    private envService: EnvironmentService,
    private textService: TextService,
    private gaService: GoogleAnalyticsService,
    private keycloakService: KeycloakService
  ) {
    // nothing.
  }

  ngOnInit(): void {
    this.textsObjects = this.textService.getTextsObjectsForIds(161, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177)
    this.loadEnv().then(() => this.getKennzahlen())
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.vbKennzahlenServiceUrl = config.apis.vbKennzahlen
    this.vbKennzahlenService.configuration.basePath = this.vbKennzahlenServiceUrl.toString()
    this.vbKennzahlenService.defaultHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + await this.keycloakService.getToken()
    })
  }

  getKennzahlen(): void {
    this.vbKennzahlenService.getKennzahlen()
      .subscribe((kennzahlen: KennzahlenResponse) => {
        this.kennzahlen = kennzahlen
        this.charts.eigen = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.eigen)
        this.charts.gruppe = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.gruppe)
        this.charts.gesamt = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.gesamt)
        this.trackTabChange('1')
      })
  }

  createChart(kennzahlen: Sparte): ChartConfiguration<'bar'>['data'] {
    return <ChartConfiguration<'bar'>['data']>{
      labels: kennzahlen?.historie.map(month => this.formatZeitraum(month.zeitraum)),
      datasets: [
        {data: kennzahlen?.historie.map(month => month.einheiten), label: ''}
      ]
    }
  }

  calcPercentageByEinheiten(einheiten:number, maxEinheiten:number): string {
    return einheiten / maxEinheiten * 100 + '%'
  }

  getCurrentDate(): string {
    return formatDate(new Date(), 'MMMM yyyy', 'de')
  }

  formatZeitraum(date:number, format = 'MMM', chars = 3): string {
    if (date > 0) {
      const _date = date.toString().substring(0, 4) + '/' + date.toString().substring(4, 6) + '/01'
      return formatDate(new Date(_date), format, 'de').substring(0, chars)
    }

    return ''
  }

  setTarget(target:'eigen' | 'gruppe' | 'gesamt', value: number) {
    this.kennzahlen.kennzahlenZiele[target] = value || 0
    this.vbKennzahlenService.postZiele(this.kennzahlen.kennzahlenZiele).subscribe()
  }

  trackTabChange(tabId:string) {
    switch (parseInt(tabId)) {
      case 1:
        this.charts.eigen = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.eigen)
        this.gaService.event('click', this.textsObjects[161].singular + ':' + this.textsObjects[172].singular + '>' + this.textsObjects[175].singular + '>' + 'uid-0092', this.textsObjects[172].singular + '>' + this.textsObjects[175].singular)
        break
      case 2:
        this.charts.gruppe = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.gruppe)
        this.gaService.event('click', this.textsObjects[161].singular + ':' + this.textsObjects[172].singular + '>' + this.textsObjects[176].singular + '>' + 'uid-0093', this.textsObjects[172].singular + '>' + this.textsObjects[176].singular)
        break
      case 3:
        this.charts.gesamt = this.createChart(this.kennzahlen.eingereichtesGeschaeft?.gesamt)
        this.gaService.event('click', this.textsObjects[161].singular + ':' + this.textsObjects[172].singular + '>' + this.textsObjects[177].singular + '>' + 'uid-0094', this.textsObjects[172].singular + '>' + this.textsObjects[177].singular)
        break
      default:
        break
    }
  }
}
